import PropTypes from 'prop-types';

import { simpleRichTextType } from 'humanity/components/simpleRichText/simpleRichText.types';
import { contentfulSysType } from 'types/common/contentfulSys.types';
import { dripiconType } from 'humanity/components/dripicon/dripicon.types';

export const featuredHighlightProps = {
  heading: PropTypes.string.isRequired,
  highlightText: simpleRichTextType.isRequired,
  icon: dripiconType.isRequired,
};

export const featuredHighlightType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...featuredHighlightProps,
});
