import PropTypes from 'prop-types';
import styled from 'styled-components';
import css from '@styled-system/css';

import { contentfulMediaType } from 'types';
import Image from 'legacy/components/image';
import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import Text from 'legacy/components/text';
import Box from 'legacy/components/box';

const StyledText = styled(Text)(
  css({
    lineHeight: 2,
  })
);

const RichTextParagraphWithImage = ({ content }) => (
  <>
    {/* Desktop Display*/}
    <Box display={['none', 'flex']} alignItems="center" height="100%">
      {content.imageLocation === 'right' ? (
        <Box display="flex" alignItems="center" height="100%">
          <Box width="60%" pr="10px">
            <StyledText>{content.paragraphText}</StyledText>
          </Box>
          <Box width="40%" display="flex" justifyContent="center">
            <Image
              src={`${getContentfulImgixUrl(content.image?.url)}`}
              htmlAttributes={{ alt: content.altText ?? '' }}
              lazyload
              width={200}
              height={200}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" height="100%">
          <Box width="40%" display="flex" justifyContent="center" pr="10px">
            <Image
              src={`${getContentfulImgixUrl(content.image?.url)}`}
              htmlAttributes={{ alt: content.altText ?? '' }}
              lazyload
              width={200}
              height={200}
            />
          </Box>
          <Box width="60%" alignItems="center">
            <StyledText>{content.paragraphText}</StyledText>
          </Box>
        </Box>
      )}
    </Box>

    {/* Mobile Display*/}
    <Box display={['flex', 'none']} flexDirection="column">
      <Box display="flex" justifyContent="center">
        <Image
          src={`${getContentfulImgixUrl(content.image?.url)}`}
          htmlAttributes={{ alt: content.altText ?? '' }}
          lazyload
          width={150}
          height={150}
        />
      </Box>
      <Box alignItems="center">
        <StyledText>{content.paragraphText}</StyledText>
      </Box>
    </Box>
  </>
);

RichTextParagraphWithImage.propTypes = {
  content: PropTypes.shape({
    altText: PropTypes.string.isRequired,
    imageLocation: PropTypes.string.isRequired,
    paragraphText: PropTypes.string.isRequired,
    image: contentfulMediaType.isRequired,
  }).isRequired,
};

export default RichTextParagraphWithImage;
