/* eslint-disable react/require-default-props, consistent-return */
import styled from 'styled-components';
import css from '@styled-system/css';
import PropTypes from 'prop-types';
import Imgix from 'react-imgix';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import Box from 'legacy/components/box';

const StyledImage = styled(Box)(({ minHeight }) =>
  css({
    '& img': {
      minHeight,
    },
  })
);

/**
 * @deprecated
 */
const Image = ({
  src,
  sizes,
  width,
  height,
  format,
  lazyload,
  htmlAttributes,
  additionalParams,
  ...props
}) => {
  const commonProps = lazyload
    ? {
        src,
        sizes,
        width,
        height,
        className: 'lazyload',
        attributeConfig: {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        },
      }
    : { src, sizes, width, height };

  return (
    // This has to be wrapped in a div, because Picture will just obliterate any styling
    // done via styled-components or styled-system attrs
    <StyledImage {...props}>
      <Imgix
        {...commonProps}
        imgixParams={{ fm: format, auto: 'compress,format', ...additionalParams }}
        htmlAttributes={{ ...htmlAttributes }}
      />
    </StyledImage>
  );
};

const sizesChecker = (props, propName, componentName) => {
  if (!props.sizes && !props.width && !props.height) {
    return new Error(
      `Prop \`${propName}\` is required if width and/or height are not specified for \`${componentName}\``
    );
  }

  if (props[propName] === undefined) return;

  const currPropType = typeof props[propName];

  if (propName === 'sizes') {
    if (currPropType !== 'string') {
      return new Error(
        `Invalid prop \`${propName}\` passed to \`${componentName}\`. \`${propName}\` must be a string.`
      );
    }
  } else if (currPropType !== 'number' && currPropType !== 'string') {
    return new Error(
      `Invalid prop \`${propName}\` of type ${currPropType} passed to \`${componentName}\`. \`${propName}\` must be a string or number.`
    );
  }
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  // eslint-disable-next-line consistent-return
  sizes: sizesChecker,
  width: sizesChecker,
  height: sizesChecker,
  format: PropTypes.oneOf(['jpg', 'png', 'pjpg']),
  lazyload: PropTypes.bool,
  htmlAttributes: PropTypes.objectOf(PropTypes.string),
  additionalParams: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

Image.defaultProps = {
  format: 'jpg',
  lazyload: false,
  htmlAttributes: { alt: '' },
  additionalParams: {},
};

export default Image;
