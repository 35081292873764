/* eslint-disable react/no-array-index-key, react/destructuring-assignment */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import css from '@styled-system/css';

import Text from 'humanity/primitives/text';
import Heading from 'humanity/primitives/heading';
import Divider from 'legacy/components/divider';
import Link from 'humanity/primitives/link';
import { simpleRichTextProps } from 'humanity/components/simpleRichText/simpleRichText.types';
import Box from 'humanity/primitives/box';

const StyledList = styled(Box)(
  css({
    display: 'inline-block',
    ml: '2em',
    textAlign: 'left',
  })
);

const StyledListItem = styled.li(
  css({
    mt: 2,
    '&::marker': {
      color: 'blue100',
    },
    '& p': {
      mt: 0,
    },
    '& b, & strong': {
      fontWeight: 600,
      color: 'blue100',
    },
  })
);

const StyledHeading = styled(Heading)(
  css({
    '& + p, & + ul, & + ol': {
      mt: 3,
    },
    'p + &, ul + &, ol + &': {
      mt: 6,
    },
    '& > a': {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    '&:first-child': {
      mt: 0,
    },
  })
);

const StyledText = styled(Text)(
  css({
    '& b, & strong': {
      fontWeight: 600,
      color: 'blue100',
    },
    'ul + &, ol + &': {
      mt: 2,
    },
  })
);

const SimpleRichText = ({ content, renderOptions = {}, ...props }) => {
  // Default options
  // One gotcha here is that if you override `[BLOCKS.EMBEDDED_ENTRY]` the entire switch statement needs to be provided
  const dtrOptions = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => (
        <StyledHeading as="h1" mt={5} variant="h1">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <StyledHeading as="h2" mt={5} variant="h2">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <StyledHeading as="h3" mt={5} variant="h3">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <StyledHeading as="h4" mt={4} variant="h4">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <StyledHeading as="h5" mt={4} variant="h5">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <StyledHeading as="h5" mt={4} variant="h6">
          {children}
        </StyledHeading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <StyledText variant="body" {...props}>
          {children}
        </StyledText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => <StyledList as="ul">{children}</StyledList>,
      [BLOCKS.OL_LIST]: (node, children) => <StyledList as="ol">{children}</StyledList>,
      [BLOCKS.LIST_ITEM]: (node, children) => <StyledListItem>{children}</StyledListItem>,
      [BLOCKS.HR]: () => <Divider as="hr" my={6} bg="blue20" />,
      [INLINES.HYPERLINK]: (node, children) => (
        <Link href={node.data?.uri}>{children}</Link>
      ),
    },
    renderMark: {
      // eslint-disable-next-line react/no-danger
      [MARKS.CODE]: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
    },
  };

  const combinedOptions = {
    renderNode: {
      ...dtrOptions.renderNode,
      ...renderOptions?.renderNode,
    },
    renderMark: {
      ...dtrOptions.renderMark,
      ...renderOptions?.renderMark,
    },
  };
  return documentToReactComponents(content, combinedOptions);
};

SimpleRichText.propTypes = {
  ...simpleRichTextProps,
};

export default SimpleRichText;
