/* eslint-disable react/no-danger */
import styled from 'styled-components';
import css from '@styled-system/css';

import { contentfulTestimonialType } from 'types';
import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import Card from 'legacy/components/card';
import Flex from 'legacy/components/flex';
import Box from 'legacy/components/box';
import Text from 'legacy/components/text';
import Image from 'legacy/components/image';
import OpenQuote from 'legacy/components/icons/openQuote.svg';
import CloseQuote from 'legacy/components/icons/closeQuote.svg';

const TestimonialWrapper = styled(Card)(
  css({
    bg: 'yellow30',
    my: [4, null, 6],
    p: ['1.5rem', null, 5],
  })
);

const QuoteText = styled(Text)(
  css({
    mt: 0,
    pl: 4,
    fontSize: [2, null, 4],
    fontStyle: 'italic',
    color: 'blue',
    lineHeight: 2,
  })
);

const StyledOpenQuote = styled('div')(
  css({
    position: 'absolute',
  })
);

const StyledCloseQuote = styled('div')(
  css({
    display: 'inline',
    ml: '0.5rem',
  })
);

const MobileAuthorImage = styled(Image)(
  css({
    display: ['block', null, 'none'],
    flexShrink: 0,
    mr: 3,
    width: 86,
    '& img': {
      borderRadius: 'circle',
    },
  })
);

const DesktopAuthorImage = styled(Image)(
  css({
    display: ['none', null, 'block'],
    flexShrink: 0,
    ml: 4,
    width: 100,
    '& img': {
      borderRadius: 'circle',
    },
  })
);

const AuthorText = styled(Text)(({ hasImage }) =>
  css({
    mt: 0,
    ml: hasImage ? [0, null, 4] : 4,
    fontWeight: 'bold',
    color: 'blue',
  })
);

const Testimonial = ({ testimonial }) => (
  <TestimonialWrapper>
    <Flex>
      <Box>
        <StyledOpenQuote>
          <OpenQuote />
        </StyledOpenQuote>
        <QuoteText>
          {testimonial.testimonialQuote}
          <StyledCloseQuote>
            <CloseQuote />
          </StyledCloseQuote>
        </QuoteText>
      </Box>
      {testimonial.authorImage && (
        <DesktopAuthorImage
          src={getContentfulImgixUrl(testimonial.authorImage?.image?.url)}
          additionalParams={{ fit: 'crop', ar: '1:1' }}
          width={100}
          height={100}
          lazyload
        />
      )}
    </Flex>
    <Flex alignItems="center" mt={3}>
      {testimonial.authorImage && (
        <MobileAuthorImage
          src={getContentfulImgixUrl(testimonial.authorImage?.image?.url)}
          additionalParams={{ fit: 'crop', ar: '1:1' }}
          width={86}
          height={86}
          lazyload
        />
      )}
      <AuthorText hasImage={!!testimonial.authorImage}>
        {testimonial.quoteAuthor}
      </AuthorText>
    </Flex>
  </TestimonialWrapper>
);

Testimonial.propTypes = {
  testimonial: contentfulTestimonialType.isRequired,
};

export default Testimonial;
