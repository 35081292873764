import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/vimeo';
import styled from 'styled-components';
import css from '@styled-system/css';

import { track } from 'utils/analytics';
import Box from 'legacy/components/box';

const Wrapper = styled(Box)(
  css({
    position: 'relative',
    pt: '56.25%',
    '.react-player': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  })
);

const Video = ({ title, url, ...props }) => {
  const handleStart = () =>
    track('video_started', {
      video_title: title,
      video_url: url,
    });

  const handlePause = () =>
    track('video_paused', {
      video_title: title,
      video_url: url,
    });

  const handlePlay = () =>
    track('video_played', {
      video_title: title,
      video_url: url,
    });

  const handleProgress = ({ played, playedSeconds }) =>
    track('video_progress', {
      video_title: title,
      video_url: url,
      video_played: played,
      video_played_seconds: playedSeconds,
    });

  const handleEnded = () =>
    track('video_ended', {
      video_title: title,
      video_url: url,
    });

  return (
    <Wrapper {...props}>
      <ReactPlayer
        className="react-player"
        url={url}
        config={{ vimeo: { title } }}
        progressInterval={5000}
        onStart={handleStart}
        onPause={handlePause}
        onPlay={handlePlay}
        onProgress={handleProgress}
        onEnded={handleEnded}
        width="100%"
        height="100%"
        controls
      />
    </Wrapper>
  );
};

Video.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Video;
