import dynamic from 'next/dynamic';

import { dripiconProps } from 'humanity/components/dripicon/dripicon.types.js';
import Link from 'humanity/primitives/link';

const Dripicon = ({ title, icon, url = '', loading = <span />, ...rest }) => {
  const Icon = dynamic(() => import('react-dripicons').then((icons) => icons[icon]), {
    loading: () => loading,
  });

  return url?.length ? (
    <Link href={url} title={title}>
      <Icon {...rest} />
    </Link>
  ) : (
    <Icon aria-label={title} {...rest} />
  );
};

Dripicon.propTypes = {
  ...dripiconProps,
};

export default Dripicon;
