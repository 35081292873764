import PropTypes from 'prop-types';

import { contentfulStatisticType } from 'types';
import Box from 'legacy/components/box';
import Flex from 'legacy/components/flex';
import Section from 'legacy/components/section';
import Statistic from 'legacy/components/statistic';

const StatisticsSection = ({ statistics, ...props }) => (
  <Section {...props}>
    <Section.Inner>
      <Flex flexWrap="wrap" justifyContent="center" maxWidth={992} mx="auto">
        {statistics.map((statistic) => (
          <Box
            width={[1, null, 1 / 3]}
            mt={[6, null, 0]}
            px={[0, null, 4]}
            key={statistic.sys.id}
          >
            <Statistic
              heading={statistic.heading}
              headingColor={statistic.headingColor}
              description={statistic.description}
            />
          </Box>
        ))}
      </Flex>
    </Section.Inner>
  </Section>
);

StatisticsSection.propTypes = {
  statistics: PropTypes.arrayOf(contentfulStatisticType).isRequired,
};

export default StatisticsSection;
