/* eslint-disable react/no-array-index-key, react/destructuring-assignment */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import styled, { ThemeContext, ThemeProvider } from 'styled-components';
import css from '@styled-system/css';
import dynamic from 'next/dynamic';
import { marked } from 'marked';

import getContentfulImgixUrl from 'utils/getContentfulImgixUrl';
import { theme as themeV2 } from 'utils/theme.v2';
import Image from 'legacy/components/image';
import Text from 'legacy/components/text';
import Heading from 'legacy/components/heading';
import ParagraphWithImage from 'legacy/components/richTextParagraphWithImage';
import CtaSection from 'legacy/components/ctaSection';
import Box from 'legacy/components/box';
import Table from 'legacy/components/table';
import Divider from 'legacy/components/divider';
import StatisticsSection from 'legacy/components/statisticsSection';
import Video from 'legacy/components/video';
import Testimonial from 'legacy/components/customerStory/testimonial';
import Link from 'legacy/components/link';
import shouldOpenNewTab from 'utils/shouldOpenNewTab';
import FeaturedHighlight from 'humanity/components/featuredHighlight';
import InlineBannerCta from 'humanity/components/inlineBannerCta';

const CtaSectionContainer = styled(Box)(
  css({
    my: 7,
    borderRadius: 'default',
  })
);

const VideoPlayer = styled(Video)(
  css({
    ml: [0, null, null, null, '-20%'],
    width: ['100%', null, null, null, '140%'],
    height: ['200px', '700px'],
  })
);

const StyledList = styled.li(
  css({
    ml: 4,
    mt: 2,
    '& p': {
      mt: 0,
    },
    '& b': {
      fontWeight: 600,
      color: 'blue',
    },
  })
);

const StyledTable = styled(Table)(
  css({
    display: 'block',
    maxWidth: 'fit-content',
    margin: '0 auto',
    overflowX: 'auto',
    mx: [0, null, null, null, 'auto'],
    width: ['100%', null, null, null, '140%'],
  })
);

const TableWrapper = styled(Box)(
  css({
    ml: [0, null, null, null, '-20%'],
    width: ['100%', null, null, null, '140%'],
  })
);

const StyledHeading = styled(Heading)(
  css({
    '& + p, & + ul': {
      mt: 3,
    },
  })
);

const StyledStatisticsSection = styled(StatisticsSection)(
  css({
    position: 'relative',
    bg: 'gray100',
    mr: ['-1.5rem', 'calc(-1.5rem - 24px)', '-24px', 0],
    ml: ['-1.5rem', 'calc(-1.5rem - 24px)', '-24px', 'calc(-50vw + 25rem)'],
    my: [7, null, 0],
    width: '100vw',
  })
);

const StyledLink = styled(Box).attrs({ as: 'a' })`
  ${css({
    position: 'relative',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: 'link',
  })};
`;

const ContentfulRichText = ({ content, links, renderOptions = {}, ...props }) => {
  const theme = useContext(ThemeContext);
  // Default options
  // One gotcha here is that if you override `[BLOCKS.EMBEDDED_ENTRY]` the entire switch statement needs to be provided
  const dtrOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const assetId = node.data.target.sys.id;
        const asset = links.assets.block.find((x) => x?.sys?.id === assetId);
        return (
          <Image
            src={`${getContentfulImgixUrl(asset?.url)}`}
            htmlAttributes={{ alt: asset?.title ?? '' }}
            sizes={`(min-width: ${theme.breakpoints.md}) 592px, calc(100vw - 3rem)`}
            lazyload
            my={4}
          />
        );
      },
      [BLOCKS.HEADING_1]: (node, children) => (
        <StyledHeading as="h1" mt={4} fontSize={['4', null, '5', null, '6']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <StyledHeading as="h2" mt={4} fontSize={['3', null, '4', null, '5']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <StyledHeading as="h3" mt={4} fontSize={['2', null, '3', null, '4']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <StyledHeading as="h4" mt={3} fontSize={['2', null, null, null, '3']}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <StyledHeading as="h5" mt={2} fontSize={2}>
          {children}
        </StyledHeading>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => <Text {...props}>{children}</Text>,
      [BLOCKS.LIST_ITEM]: (node, children) => <StyledList>{children}</StyledList>,
      [BLOCKS.HR]: () => <Divider as="hr" my={5} bg="gray90" />,
      [INLINES.HYPERLINK]: (node, children) => {
        const title = node.content?.[0]?.value ?? 'Human Interest Video';

        if (node?.data?.uri?.includes('player.vimeo.com/video')) {
          return <VideoPlayer title={title} url={node.data.uri} />;
        }

        if (node?.data?.uri?.includes('youtube.com/embed')) {
          return (
            <div>
              <iframe
                title={title}
                src={node.data.uri}
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
              />
            </div>
          );
        }

        if (
          node?.data?.uri?.includes('https://humaninterest.com/safe-harbor-calculator')
        ) {
          const SafeHarborCalculator = dynamic(
            () => import('./calculator/safeHarborCalculator')
          );

          return (
            <SafeHarborCalculator
              width={['100%', null, null, null, '140%']}
              my={7}
              ml={[0, null, null, null, '-20%']}
            />
          );
        }

        if (node?.data?.uri?.includes('https://humaninterest.com/plan-cost-calculator')) {
          const PlanCostCalculator = dynamic(
            () => import('./calculator/planCostCalculator')
          );

          return (
            <PlanCostCalculator
              width={['100%', null, null, null, '140%']}
              my={7}
              ml={[0, null, null, null, '-20%']}
            />
          );
        }

        if (
          node?.data?.uri?.includes('https://humaninterest.com/admin-match-calculator') ||
          node?.data?.uri?.includes('https://humaninterest.com/employer-match-calculator')
        ) {
          const AdminMatchCalculator = dynamic(
            () => import('./calculator/employerMatchCalculator')
          );

          return (
            <AdminMatchCalculator
              width={['100%', null, null, null, '140%']}
              my={7}
              ml={[0, null, null, null, '-20%']}
            />
          );
        }

        if (shouldOpenNewTab(node?.data?.uri)) {
          return (
            <Link external target="_blank" href={node.data.uri} rel="noreferrer noopener">
              {children}
            </Link>
          );
        }

        return <StyledLink href={node.data.uri}>{children}</StyledLink>;
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entryId = node.data.target.sys?.id;
        const entry = links.entries.block.find((x) => x?.sys?.id === entryId);
        const entryType = entry?.__typename;

        switch (entryType) {
          case 'Image':
            return (
              <Box my={4}>
                <Image
                  my={4}
                  src={`${getContentfulImgixUrl(entry.image.url)}`}
                  htmlAttributes={{ alt: entry.title ?? '' }}
                  sizes={`(min-width: ${theme.breakpoints.md}) 592px, calc(100vw - 3rem)`}
                  lazyload
                  width="100%"
                />
                {entry.description && (
                  <Text
                    mt={3}
                    fontSize={1}
                    fontStyle="italic"
                    textAlign="center"
                    color={theme.colors.gray60}
                  >
                    {entry.description}
                  </Text>
                )}
              </Box>
            );
          case 'ParagraphWithImage':
            return <ParagraphWithImage content={entry} />;
          case 'CtaSection': {
            const ctaPresent = !!entry.cta;
            if (ctaPresent) {
              return (
                <CtaSectionContainer>
                  <CtaSection
                    size="small"
                    borderRadius="large"
                    title={entry.heading}
                    content={marked(entry.subheading)
                      .replace('<p>', '')
                      .replace('</p>', '')}
                    variant="embedded"
                    buttonText={entry.cta.label}
                    link={entry.cta.url}
                  />
                </CtaSectionContainer>
              );
            }

            return (
              <CtaSectionContainer>
                <CtaSection
                  title={entry.heading}
                  content={marked(entry.subheading)
                    .replace('<p>', '')
                    .replace('</p>', '')}
                  variant="embedded"
                  size="small"
                  hideCta
                />
              </CtaSectionContainer>
            );
          }
          case 'Table': {
            const { tableData } = entry.table;
            const theadData = tableData[0];
            return (
              <Box my={4}>
                <TableWrapper>
                  <StyledTable>
                    <Table.Head>
                      <Table.HeadRow>
                        {theadData.map((column, columnIndex) => (
                          <Table.Heading
                            key={columnIndex}
                            dangerouslySetInnerHTML={{ __html: column }}
                          />
                        ))}
                      </Table.HeadRow>
                    </Table.Head>
                    <Table.Body>
                      {tableData.slice(1).map((row, rowIndex) => (
                        <Table.BodyRow key={rowIndex}>
                          {row.map((column, columnIndex) => (
                            <Table.Cell
                              key={columnIndex}
                              dangerouslySetInnerHTML={{ __html: column }}
                            />
                          ))}
                        </Table.BodyRow>
                      ))}
                    </Table.Body>
                  </StyledTable>
                </TableWrapper>
                {!!entry.description && (
                  <Text mt={3} fontSize={1} fontStyle="italic" textAlign="center">
                    {entry.description}
                  </Text>
                )}
              </Box>
            );
          }
          case 'Testimonial': {
            return <Testimonial testimonial={{ ...entry }} />;
          }
          case 'StatisticsSection': {
            return (
              <StyledStatisticsSection statistics={entry.statisticsCollection?.items} />
            );
          }
          case 'FeaturedHighlight': {
            return (
              <ThemeProvider theme={themeV2}>
                <FeaturedHighlight
                  heading={entry.heading}
                  highlightText={entry.highlightText}
                  icon={entry.icon}
                />
              </ThemeProvider>
            );
          }
          case 'InlineBannerCta': {
            return (
              <ThemeProvider theme={themeV2}>
                <InlineBannerCta
                  heading={entry.heading}
                  subheading={entry.subheading}
                  variant={entry.variant}
                  cta={entry.cta}
                />
              </ThemeProvider>
            );
          }
          default:
            return null;
        }
      },
    },
    renderMark: {
      // eslint-disable-next-line react/no-danger
      [MARKS.CODE]: (text) => <span dangerouslySetInnerHTML={{ __html: text }} />,
    },
  };

  const combinedOptions = {
    renderNode: {
      ...dtrOptions.renderNode,
      ...renderOptions?.renderNode,
    },
    renderMark: {
      ...dtrOptions.renderMark,
      ...renderOptions?.renderMark,
    },
  };
  return documentToReactComponents(content, combinedOptions);
};

ContentfulRichText.propTypes = {
  content: PropTypes.exact({
    data: PropTypes.object.isRequired,
    content: PropTypes.array.isRequired,
    nodeType: PropTypes.oneOf(['document']).isRequired,
  }),
  links: PropTypes.shape({
    entries: PropTypes.shape({
      block: PropTypes.arrayOf(PropTypes.object),
      inline: PropTypes.arrayOf(PropTypes.object),
      hyperlink: PropTypes.arrayOf(PropTypes.object),
    }),
    assets: PropTypes.shape({
      block: PropTypes.arrayOf(PropTypes.object),
      inline: PropTypes.arrayOf(PropTypes.object),
      hyperlink: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
  renderOptions: PropTypes.shape({
    renderNode: PropTypes.object,
    renderMark: PropTypes.object,
  }),
};
export default ContentfulRichText;
