import styled from 'styled-components';
import { variant as styledVariant } from 'styled-system';
import css from '@styled-system/css';

import Flex from 'humanity/primitives/flex';
import Text from 'humanity/primitives/text';
import Cta from 'humanity/components/cta';
import {
  inlineBannerCtaDefaultProps,
  inlineBannerCtaProps,
} from 'humanity/components/inlineBannerCta/inlineBannerCta.types.js';
import Heading from 'humanity/primitives/heading';

const BannerContainer = styled(Flex)(
  css({
    borderRadius: 'md',
    flexDirection: ['column', null, 'row'],
    justifyContent: 'space-between',
    alignItems: ['unset', null, 'center'],
    py: 5,
    px: 4,
    my: 4,
  }),
  styledVariant({
    prop: 'variant',
    variants: {
      primary: {
        bg: 'blue100',
      },
      secondary: {
        bg: 'teal50',
      },
    },
  })
);

const StyledFlex = styled(Flex)(
  css({
    flexDirection: 'column',
    pr: [0, null, 5],
    pb: [5, null, 0],
  })
);

const InlineBannerCta = ({ variant, heading, subheading, cta, ...props }) => {
  const ctaVariant = variant === 'primary' ? 'secondary' : 'primary';
  const textColor = variant === 'primary' ? 'white' : 'blue100';

  return (
    <BannerContainer variant={variant} {...props}>
      <StyledFlex>
        <Heading color={textColor} variant="h6">
          {heading}
        </Heading>
        <Text color={textColor}>{subheading}</Text>
      </StyledFlex>
      <Cta
        btnVariant={ctaVariant}
        data-testid="InlineBannerCtaButton"
        label={cta.label}
        location="inline_banner"
        openInNewTab={cta.openInNewTab}
        type="button"
        url={cta.url}
      />
    </BannerContainer>
  );
};

InlineBannerCta.propTypes = {
  ...inlineBannerCtaProps,
};

InlineBannerCta.defaultProps = {
  ...inlineBannerCtaDefaultProps,
};

export default InlineBannerCta;
