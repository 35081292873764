import PropTypes from 'prop-types';
import { Document } from '@contentful/rich-text-types';

const richText = PropTypes.exact({
  data: PropTypes.object.isRequired,
  content: PropTypes.array.isRequired,
  nodeType: PropTypes.oneOf(['document']).isRequired,
});

export const simpleRichTextProps = {
  content: richText,
  renderOptions: PropTypes.shape({
    renderNode: PropTypes.object,
    renderMark: PropTypes.object,
  }),
};

export const simpleRichTextType = PropTypes.shape({
  json: richText.isRequired,
});

export type SimpleRichTextType = {
  json: Document;
};
