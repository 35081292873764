import styled from 'styled-components';
import { space, typography } from 'styled-system';
import css from '@styled-system/css';

const Table = styled.table(
  css({
    width: '100%',
    borderRadius: 'default',
    borderSpacing: 0,
    px: 2,
    pb: 2,
    bg: 'blue100',
    fontFamily: 'body',
    color: 'text',
    '& b': {
      fontWeight: 700,
      color: 'blue',
    },
    '& highlight': {
      fontWeight: 700,
      color: 'teal40',
    },
  }),
  space
);

Table.Head = styled.thead(css({}));

Table.Heading = styled.th(
  css({
    p: 3,
    fontWeight: 400,
    textAlign: 'left',
  }),
  typography
);

Table.HeadRow = styled.tr(css({}));

Table.Body = styled.tbody(css({}));

Table.BodyRow = styled.tr(
  css({
    '&:nth-of-type(odd)': {
      bg: 'white',
      '& td:first-child': {
        borderTopLeftRadius: 'default',
        borderBottomLeftRadius: 'default',
      },
      '& td:last-child': {
        borderTopRightRadius: 'default',
        borderBottomRightRadius: 'default',
      },
    },
  })
);

Table.Cell = styled.td(
  css({
    py: 2,
    px: 3,
  })
);

export default Table;
