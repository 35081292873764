import PropTypes from 'prop-types';

import { contentfulSysType } from 'types/common/contentfulSys.types';

export const dripiconProps = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string,
  loading: PropTypes.node,
};

export const dripiconType = PropTypes.shape({
  sys: contentfulSysType.isRequired,
  ...dripiconProps,
});
