import styled from 'styled-components';
import css from '@styled-system/css';

import { theme } from 'utils/theme.v2';
import Box from 'humanity/primitives/box';
import Flex from 'humanity/primitives/flex';
import Heading from 'humanity/primitives/heading';
import SimpleRichText from 'humanity/components/simpleRichText';
import Dripicon from 'humanity/components/dripicon';
import { featuredHighlightProps } from 'humanity/components/featuredHighlight/featuredHighlight.types.js';

const HighlightContainer = styled(Flex)(
  css({
    borderRadius: 'md',
    border: `3px solid ${theme.colors.teal20}`,
    background: `linear-gradient(180deg, ${theme.colors.teal10} 0%, ${theme.colors.white}) 100%`,
    flexDirection: 'column',
    py: 5,
    px: 4,
    my: 4,
  })
);

const StyledFlex = styled(Flex)(
  css({
    alignItems: 'center',
    mb: 4,
    flexFlow: ['row-reverse', null, 'row'],
    columnGap: 2,
  })
);

const StyledIconContainer = styled(Flex)(
  css({
    borderRadius: 'circle',
    bg: 'teal20',
    minWidth: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
  })
);

const IconLoading = () => <Box p="12px" />;

const FeaturedHighlight = ({ heading, highlightText, icon, ...props }) => (
  <HighlightContainer {...props}>
    <StyledFlex>
      <StyledIconContainer>
        <Dripicon title={icon.title} icon={icon.icon} loading={<IconLoading />} />
      </StyledIconContainer>
      <Heading variant="h6">{heading}</Heading>
    </StyledFlex>
    <SimpleRichText content={highlightText.json} />
  </HighlightContainer>
);

FeaturedHighlight.propTypes = {
  ...featuredHighlightProps,
};

export default FeaturedHighlight;
