import PropTypes from 'prop-types';
import styled from 'styled-components';
import { color } from 'styled-system';
import css from '@styled-system/css';
import { BLOCKS } from '@contentful/rich-text-types';

import { contentfulRichTextType } from 'types';
import Box from 'legacy/components/box';
import Text from 'legacy/components/text';
import SimpleRichText from 'legacy/components/humanity-v1/primitives/simpleRichText';

const StatisticHeading = styled.p(
  css({
    fontSize: '6rem',
    lineHeight: 1,
    letterSpacing: '-0.04em',
  }),
  color
);

const renderOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <Text mt={2} lineHeight={2} color="blue">
        {children}
      </Text>
    ),
  },
};

const Statistic = ({ heading, headingColor, description }) => (
  <Box>
    <StatisticHeading color={headingColor}>{heading}</StatisticHeading>
    <SimpleRichText content={description?.json} renderOptions={renderOptions} />
  </Box>
);

Statistic.propTypes = {
  heading: PropTypes.string.isRequired,
  headingColor: PropTypes.oneOf(['teal', 'blue', 'yellow']).isRequired,
  description: contentfulRichTextType.isRequired,
};

export default Statistic;
