import PropTypes from 'prop-types';

import { ctaType } from 'humanity/components/cta/cta.types';

export const inlineBannerCtaProps = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  cta: ctaType.isRequired,
};

export const inlineBannerCtaDefaultProps = {
  variant: 'primary',
};

export const inlineBannerCtaType = PropTypes.shape({
  ...inlineBannerCtaProps,
});
